import React, { useEffect, useState } from 'react';

export default function RetentionDialog({ retentionDialogShow, showData, onClose }) {
  const [timerId, setTimerId] = useState();
  useEffect(() => {
    if (retentionDialogShow) {
      let timer = setInterval(() => {
        onClose();
      }, 2000);
      setTimerId(timer);
    } else {
      clearInterval(timerId);
    }
  }, [retentionDialogShow]);
  return (
    <div>
      {retentionDialogShow && (
        <div className="retention-dialog" onClick={onClose}>
          <img src={showData?.img} alt="" />
        </div>
      )}
    </div>
  );
}
