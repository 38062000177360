import axios from 'axios';

// const goodsRequestUrl = '/api';
// const goodsRequestUrl = 'http://192.168.1.198:8080';
const goodsRequestUrl = 'https://hl.hntiannuo.net/api';
// const goodsRequestUrl = 'http://192.168.0.106:8080';
const api = axios.create({
  baseURL: goodsRequestUrl,
  timeout: 120000,
});

/// 获取商品列表数据
export async function getGoodsData(code) {
  var res = await api.get(`/growing/expandopen/getPopularizeGoodList/${code}`);
  return res.data;
}

/// 获取验证码
export async function getSMSCode(body) {
  var res = await api.post('/growing/expandopen/sendCode', body);
  return res.data;
}

/// 提交订单
export async function commitOrder(body) {
  var res = await api.post('/growing/expandopen/submitOrder', body);
  return res.data;
}

// 获取号码池
export async function getCardPool(data) {
  // let list = [];
  // for (let i = 0; i < 100; i++) {
  //   let str = '1';
  //   for (let j = 0; j < 10; j++) {
  //     str += Math.floor(Math.random() * 10);
  //   }
  //   str = str.substring(0, 11);
  //   list.push(str);
  // }
  // return { code: 200, data: list };
  var res = await api.post('/growing/expandopen/chooseNum', { ...data });

  return res.data;
}

// 获取订单列表
export async function getQueryOrder(data) {
  var res = await api.post('/growing/expandopen/queryH5OrderListByPhonePage', data);
  return res.data;
}

// 获取挽留列表
export async function getRetentionList(code) {
  var res = await api.get(`/growing/expandopen/getRetentionList/${code}`);
  return res.data;
}
// 查询物流信息详情
export async function getQueryTrackLogisticsDetail(data) {
  var res = await api.get('/growing/website/queryTrackLogisticsDetail', { params: { ...data } });
  return res.data;
}
// 获取省市地址
export async function getIpAddress() {
  var res = await api.get('/growing/expandopen/getIpAddress');
  return res.data;
}
