import React from 'react';

export default function MCheckbox({ value, onChange, className }) {
  function handleChange() {
    if (onChange) {
      onChange(!value);
    }
  }
  return (
    <div className={`m-checkbox ${className}`} onClick={handleChange}>
      <img className="m-size" src={value ? '/pic/icon_radio_checked.png' : '/pic/icon_radio_default.png'} alt="" />
    </div>
  );
}
