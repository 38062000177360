import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
// 倒计时-时分秒
const COUNTDOWN_NUM = 56391;
export default function Countdown({ currentGoods }) {
  useEffect(() => {
    startCountdownTimer();
  }, [currentGoods.id]); // eslint-disable-line

  // 倒计时计算时间和timer
  const countdownTimer = useRef({});
  const [countdownTime, setCountdownTime] = useState(0);

  // 开始倒计时
  function startCountdownTimer() {
    if (currentGoods?.showCountDown === 'N') return;
    if (countdownTimer.current.timer) {
      clearInterval(countdownTimer.current.timer);
    }
    // 获取在后端设置的秒数
    const countDownSecond = currentGoods.countDownSecond;

    // (当前时间 - 创建时间) / 秒数取余
    const specificDate = dayjs(currentGoods.createTime); // 创建时间
    const currentDate = dayjs(); // 当前日期
    const differenceInMilliseconds = currentDate.diff(specificDate); // 毫秒
    const differenceInSeconds = differenceInMilliseconds / 1000; // 毫秒转秒
    // const number = COUNTDOWN_NUM - (Math.floor(differenceInSeconds) % COUNTDOWN_NUM);
    let num = countDownSecond ? countDownSecond : COUNTDOWN_NUM;
    const number = num - (Math.floor(differenceInSeconds) % num);
    countdownTimer.current.time = number;
    countdownTimer.current.timer = setInterval(() => {
      countdownTimer.current.time--;
      setCountdownTime(countdownTimer.current.time);
      if (countdownTimer.current.time <= 0) {
        startCountdownTimer();
      }
    }, 1000);
  }
  // 将时长(秒)转化为时分秒的形式
  function getTime(value) {
    let secondTime = parseInt(value); // 秒
    let minuteTime = 0; // 分
    let hourTime = 0; // 时
    if (secondTime > 60) {
      minuteTime = parseInt(secondTime / 60);
      secondTime = parseInt(secondTime % 60);
      if (minuteTime > 60) {
        hourTime = parseInt(minuteTime / 60);
        minuteTime = parseInt(minuteTime % 60);
      }
    }
    //若秒数是个位数，前面用0补齐
    secondTime = secondTime < 10 ? '0' + secondTime : secondTime;
    if (minuteTime > 0) {
      minuteTime = minuteTime < 10 ? '0' + minuteTime : minuteTime;
    }
    if (hourTime > 0) {
      hourTime = hourTime < 10 ? '0' + hourTime : hourTime;
    }
    return { hourTime: hourTime, minuteTime: minuteTime, secondTime: secondTime };
  }

  // 时分秒
  let countdownData = getTime(countdownTime);
  return (
    <>
      <span>{0}</span>天<span>{countdownData?.hourTime}</span>时<span>{countdownData?.minuteTime}</span>分
      <span>{countdownData?.secondTime}</span>秒
    </>
  );
}
