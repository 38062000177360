import MobileSelect from 'mobile-select';
import React, { useRef } from 'react';
import areaData from './area.json';

export default function MCityPicker({ onChange, select }) {
  const pickerRef = useRef();
  // function createData() {
  //   let map = {};
  //   provinceData.forEach((p) => {
  //     map[p.province] = JSON.parse(JSON.stringify(p));
  //     map[p.province].children = [];
  //   });
  //   cityData.forEach((c) => {
  //     let key = c.province + c.city;
  //     if (map[c.province]) {
  //       map[key] = JSON.parse(JSON.stringify(c));
  //       map[key].children = [];
  //       map[c.province].children.push(map[key]);
  //     }
  //   });
  //   areaData.forEach((a) => {
  //     let ckey = a.province + a.city;
  //     if (map[ckey]) {
  //       map[ckey].children.push(JSON.parse(JSON.stringify(a)));
  //     }
  //   });
  //   let _data = [];
  //   Object.keys(map).forEach((v) => {
  //     let d = map[v];
  //     if (!d.city) {
  //       _data.push(d);
  //     }
  //   });
  //   return _data;
  // }

  function handleRef(v) {
    if (pickerRef.current) return;
    let data = areaData;
    pickerRef.current = new MobileSelect({
      trigger: '#m-city-picker',
      title: '地址选择',
      wheels: [{ data }],
      keyMap: { id: 'value', value: 'label', childs: 'children' },
      onChange: (cur, idx) => {
        onChange(cur.map((i) => i.label));
      },
    });
  }
  return (
    <div id="m-city-picker" className={`m_city_picker city ${select ? '' : 'placeholder'}`} ref={(v) => handleRef(v)}>
      请选择省/市/区县
    </div>
  );
}
