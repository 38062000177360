import React, { useEffect, useState } from 'react';
import { copyToClipboard } from '../../utils';
import { getQueryTrackLogisticsDetail } from '../../api';

export default function ProtocolDialog({ record, onClose }) {
  const [trackLog, setTrackLog] = useState({});
  const [logisticsTrackVos, setLogisticsTrackVos] = useState([]);
  useEffect(() => {
    getQueryTrackLogistics();
  }, [record]);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }

  // 获取挽留数据
  async function getQueryTrackLogistics() {
    try {
      var res = await getQueryTrackLogisticsDetail({ id: record?.id });
      if (res.code === 200) {
        setTrackLog(res.data);
        setLogisticsTrackVos(res.data.logisticsTrackVos);
      } else {
        setTrackLog({});
      }
    } catch {
      setTrackLog({});
    }
  }
  return (
    <div className="popup-dialog">
      <div className="popup-mask" onClick={handleClose} />
      <div className="popup-center" onClick={() => {}}>
        <div className="popup-title-block">
          <div className="popup-title">详细信息</div>
          <div className="popup-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="popup-content">
          <div className="popup-content-inner">
            {/* 顶部物流信息盒子 */}
            <div className="popup-inner-top">
              {record?.expressName}
              <span>{record?.expressNum}</span>
              <span onClick={() => copyToClipboard(record?.expressNum)}>复制</span>
            </div>
            {/* 物流轨迹盒子 */}
            <div className="popup-inner-content">
              {logisticsTrackVos.length === 0 && (
                <div className="popup-inner-null">
                  <img src="/pic/icon_empty.png" alt="" />
                  <span>快递策马加鞭向您赶来，请耐心等待</span>
                </div>
              )}
              {logisticsTrackVos.map((item, idx) => (
                <div key={idx} className="content-item">
                  <div className="content-dot">
                    <div className={`${idx === 0 && 'content-weight-dot'} content-item-dot`}></div>
                    {idx < logisticsTrackVos.length - 1 && <div className="content-item-line"></div>}
                  </div>
                  <div className="content-timer">
                    <span className={`content-timer-time ${idx === 0 ? 'content-weight-time' : ''}`}>{item.time}</span>
                    <span className={`${idx === 0 ? 'content-weight-context' : ''}`}>{item.context}</span>
                  </div>
                </div>
              ))}
            </div>
            {/* 收件人信息盒子 */}
            <div className="popup-inner-bottom">
              <div className="popup-inner-left">收</div>
              <div className="popup-inner-right">
                {record?.receiverName}
                <span>{trackLog?.receiverPhone}</span>
                <span>
                  {trackLog?.province}&nbsp;
                  {trackLog?.city}&nbsp;
                  {trackLog?.county}&nbsp;
                  {trackLog?.receiverAddress}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';
