import React, { useEffect } from 'react';

export default function ResellDialog({ onClose }) {
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="resell-dialog">
      <div className="resell-mask" onClick={handleClose} />
      <div className="resell-center" onClick={() => {}}>
        <div className="resell-title-block">
          <div className="resell-title">转卖或转借电话卡风险告知书</div>
          <div className="resell-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="resell-content">
          <div className="resell-content-line font-weight-600">
            尊敬的客户您好，请确认该号码办理后<span className="text-color-red">由您本人使用</span>
            ，并对周围非法使用电话卡的行为积极检举揭发，具体内容如下：
          </div>
          <div className="resell-content-line">
            <span className="font-weight-600">一、如果您把手机卡交由他人使用，</span>
            <span className="text-color-red font-weight-600">他人使用该号码从事骚扰、诈骗</span>
            <span className="font-weight-600">等违规违法活动，您可能涉嫌以下犯罪：</span>
            为他人实施的电信网络诈骗犯罪行为“提供手机卡、信用卡、资金支付结算账户、通讯工具”的，以共同犯罪论处。依据《中华人民共和国刑法》相关规定，
            <span className="font-weight-600">构成帮助信息网络犯罪活动罪</span>的，
            <span className="text-color-red font-weight-600">处三年以下有期徒刑或者拘役，并处或者单处罚金。</span>
          </div>
          <div className="resell-content-line">
            <span className="text-color-red font-weight-600">二、如果您存在上述涉嫌违法犯罪行为，将导致以下后果：</span>
            <div>1、移动公司将配合公安等部门对您名下的所有号码进行1机、销户等操作。</div>
            <div>
              2、您将被纳入不良信用名单，
              <span className="text-color-red font-weight-600">
                6个月内不能在任何一家运营商办理手机、宽带、固定电话等新入网业务。
              </span>
            </div>
            <div>
              3、不法分子冒用办卡用户您的身份信息，套取该手机号未注销的您相关APP的登录密码、验证码等，从中谋取利益
              <span className="text-color-red font-weight-600">导致您个人信息泄露，甚至财产损失</span>；
              号码产生巨额欠费，您作为机主将承担清偿责任，否则将影响您的个人征信。
            </div>
          </div>
          <div className="resell-content-line">
            三、为了保护您家人的生命财产安全，凡知悉买卖、转借、转租手机卡、身份证件等信息的公民，积极检举揭发，公安机关将对检举揭发人员的信息依法予以保护和保密，并执行
            <span className="text-color-red font-weight-600">奖励</span>政策。
          </div>
          <div className="resell-content-line">
            四、以上情况<span className="text-color-red font-weight-600">本人已知悉</span>
            ，并严格遵照执行，否则，
            <span className="text-color-red font-weight-600">由转卖或转借电话卡导致的一切经济和法律责任由自己承担</span>
            。
          </div>
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';
