import React, { useEffect } from 'react';

export default function ProtocolDialog({ type = 'user', onClose }) {
  let p;
  if (type === 'user') {
    p = userProtocol;
  } else if (type === 'inter') {
    p = interProtocol;
  } else if (type === 'real') {
    p = realProtocol;
  } else {
    p = protectProtocol;
  }
  useEffect(() => {
    window.document.body.style.overflow = 'hidden';
  }, []);

  function handleClose() {
    window.document.body.style.overflow = '';
    onClose();
  }
  return (
    <div className="protocol-dialog">
      <div className="protocol-mask" onClick={handleClose} />
      <div className="protocol-center" onClick={() => {}}>
        <div className="protocol-title-block">
          <div className="protocol-title">{p.title}</div>
          <div className="protocol-close" onClick={handleClose}>
            <img alt="" className="close-icon" src={closeIcon} />
          </div>
        </div>
        <div className="protocol-content">
          {p.content.map((v, i) => (
            <div key={i} className="protocol-content-line">
              {v}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

let closeIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACSUlEQVR4nO2avXKbQBSF9Th6CBUpXbpwYcfyT+L8PIr2nLPvlDKly8SWnThOYj+AyxRKEcggBliBgAXmfjOUcPd8u8Cyy2xmGIZhGIZhGIZh1Md7P3fOLfqu65xbeO/nfdfdwns/J/kM4KVPCc65BYAXks/RJKxWq1ckP5HcJMc3ABdd13XOHZP8kqn7meRB13W3KAj/XwLJ867qFoSPIyEzBPMN2XR1O1TVJPkHwGHbNSsBcJH0eFGD7gCctVWrouc3AJ4kfWyrVi1InpO8L5MgablvjarwJH+T/NBGlsZUSZC03kfC4MOneO/PSN6VDNE1gNO616wKL+mXpPddZGkMgFIJJG/rSAj0/PDCp0haSlqXSZD0OnSNQPifJN/1kaUxkpYACiVIunHOlUoYffgUAKckb0ueCTckT/LnBO75R0lXMbI0pkoCya9ZCYGefwQwrvApu8wYd5jhHcXOsRchCZMOn0LyJBn2RUGLjgdJb2K3u1UkHe8o4YHkZez2dkIioexhtyH5fbLhZ7Pg037aAnYIP10JNcJPT0LoPZ8cpfOE2O3fi10mOQCOJikhMOy3XnUkL5NhP43bIRD+R9EkZzISQuFJvi07d/QSQl91VeFTRish9D1f55N2dBJCKzlNFjNGIyG0gMk9lrEGL6HL8CmDlRDatGhz6XpwEmIsYw1qxkjygP+2pPMNeWKH21UVI+G6993hvARJnYbP1M1L6D98SvqLTFfDvozM7RDvF5kU7/08Rg8AOIwe3jAMwzAMwzAMY6z8BS3ngNlcif7iAAAAAElFTkSuQmCC';

let userProtocol = {
  title: '个人信息收集证明',
  content: [
    '尊敬的客户：',
    '《全国人民代表大会常务委员会关于加强网络信息保护的决定》、 《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号） 和《电话用户真实身份信息登记规定》（工业和信息化部令第25号） 等国家法律法规的要求，客户在中国电信股份有限公司各类营业网点 （含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等 ）办理固定电话、移动电话（含无线上网卡）入网、 过户以及需要出示客户证件的有关业务时， 客户应配合出示有效证件原件并进行查验、 登记，登记信息包括姓名、证件类型、号码及地址等。 同时，为更好地提供服务，需要客户提供如联系人、 联系电话、通信地址、电子邮箱等信息。 客户本人持有效证件可通过自有营业厅查询和/或更正本人信息， 或登录手机营业厅查询本人信息。',
    '如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。',
    '为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全 、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、 必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息， 并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：',
    '(a)事先获得客户的明确授权；',
    '(b)根据有关的法律法规要求；',
    '(c)按照相关司法机关和/或政府主管部门的要求；',
    '(d)为维护社会公众的利益所必需且适当；',
    '(e)为维护我公司或客户的合法权益所必需且适当。',
  ],
};

let interProtocol = {
  title: '入网许可协议',
  content: [
    '1.您应当为具有完全民事行为能力的自然人、个体工商户、农村承包经营户、 法人、非法人组织、获得法人或非法人组织授权的依法登记的经营单位。 如您为无民事行为能力人或限制民事行为能力人， 您签署本协议应当经过其法定代理人同意并由法定代理人同时签署本协议。',
    '2.在签署本协议前，请您或其代理人仔细阅读本协议各条款，如有疑问请及时咨询。',
    '3.您或其代理人签署本协议即视为完全理解并同意接受本协议的全部条款。',
    '4.您所需的业务内容、办理方式以及资费方案请详见相关业务说明。 根据《中华人民共和国合同法》《中华人民共和国网络安全法》 《中华人民共和国电信条例》及其他有关法律、 法规的规定，您同意就我方提供的通信服务的相关事宜，达成如下协议：',
    '一、用户真实身份信息登记要求',
    '1.根据《电话用户真实身份信息登记规定》（工业和信息化部令第25号） 及有关登记实施规范要求，您办理入网手续时须向我方出示有效证件、 提供真实身份信息；您委托他人办理入网手续的，代理人须出示您和代理人的有效证件， 提供您和代理人的真实身份信息；并且配合我方进行身份信息查验、登记，留存办理手续的记录。',
    '2.您为个人用户的，线上办理入网时使用二代居民身份证。',
    '3.您为单位用户的，办理入网时应出示统一社会信用代码证、或营业执照、 或事业单位法人证书、社会团体法人登记证书、或法律、 行政法规和国家规定的其他有效证件或者证明文件。 单位办理登记的，除出示以上相应证件外，还应当出示经办人的有效证件和单位的授权书。',
    '4.单位用户办理移动电话入网时， 必须向我方提供与每一张移动电话卡一一对应的实际使用人的有效证件， 由我方进行查验并登记实际使用人的身份信息。',
    '5.您应当保证登记的信息及提供的身份信息登记资料真实、有效、准确、完整， 并有义务配合我方进行查验。您可以通过我方相应渠道查询已登记的身份信息。 如不符合用户真实身份信息登记相关法律法规和规定、或登记信息发生变化的， 您应当及时到我方营业网点更正或变更。 因您登记信息不符合规定或未及时办理更正或变更手续等造成的后果，由您自行承担。',
    '6.您申请入网时，在中国电信全国范围内登记在同一个个人用户名下的移动电话卡达到 5张的，我方根据国家相关规定不再为其开办新的电话卡。',
    '7.我方要求您提供担保的，您应当按照我方要求办理相应担保手续。',
    '二、您的权利和义务',
    '1.您有权监督我方的通信服务质量，及时提出改善意见和建议； 对我方提供的服务持有异议的，可以向我方进行咨询或投诉。',
    '2.您有权自主选择我方依法提供的各类通信业务，有权自主选择我方公示的资费方案， 有权自主选择我方提供的各类业务办理、咨询、查询和投诉渠道。',
    '3.您有权自主选择取得入网许可的终端、设备，并负责安装、 调测和维护（包括建筑内自建管线的维护）。 您自主选择的终端、设备应当具备符合我方网络的相应功能， 否则可能无法支持所选择的通信服务，您将自行承担损失。',
    '4.您对我方提供给您使用但所有权归我方的线路、设备或终端有保管责任， 不得转借、出租、出售或赠与他人，也不得用于非我方提供的业务中。 由于您原因造成该等线路、设备或终端损坏、丢失的，您需承担修复费用或按价赔偿。',
    '5.您应当按照约定的交费时间和方式及时、足额交纳通信费用。 通信业务一般按自然月为一个计费与结算周期，双方另有约定的除外。 逾期交纳通信费用（欠费）的，您须每日按所欠费用3‰的标准支付违约金， 并承担相应违约责任。由于通信设备产生费用清单及相关处理会有时延（如国际漫游业务等）， 可能会发生上一计费周期的部分通信费用计入下一计费周期收取的情况。',
    '6.您可以自愿订购或退订第三方服务 提供商通过我方网络提供的增值通信服务， 并同意我方根据第三方服务提供商的委托代为收取相关费用。 如您对代收费用有异议，可以向第三方或我方提出，由第三方自行解决或由我方协调第三方解决。',
    '7.您对交纳的通信费用及代收费用有异议的，应当在我方计费原始数据保存期限内向我方提出。',
    '8.您有权自主选择或终止以代扣、 银行托收等方式支付通信费用， 并自主到银行等机构或具备受理条件的营业网点等渠道办理相应手续。',
    '9.您通信自由和通信秘密受法律保护。公安机关、国家安全机关、 人民检察院及依据法律法规有权进行调查的其他部门根据相关规定提出调查要求的，我方将依法配合。',
    '10.您不得二次转售、倒卖名下持有的电话卡。 如需转让给他人使用或实际使用人信息发生变化， 您须按本协议关于用户真实身份信息登记的约定， 办理过户手续或变更实际使用人信息， 否则我方有权采取关停号码、解除协议等措施。 由此产生的后果，我方不承担责任。',
    '11.您不得利用我方通信资源、通信服务等制作、复制、发布、 传播含有以下内容的信息或从事下列违法违规、违背公序良俗的活动， 否则我方有权暂停或限制向您提供通信服务， 直至终止通信服务。由此产生的后果，我方不承担责任：',
    '（1）反对宪法所确定的基本原则，破坏国家宗教政策，宣扬邪教和封建迷信的；',
    '（2）危害国家安全、荣誉和利益，泄露国家秘密，颠覆国家政权，破坏国家统一的；',
    '（3）宣扬恐怖主义、极端主义，煽动民族仇恨、民族歧视，破坏民族团结的；',
    '（4）散布虚假信息、诈骗信息、谣言，扰乱经济秩序和社会秩序，破坏社会稳定的；',
    '（5）散布涉及实施诈骗，制作或销售违禁物品、管制物品，淫秽、色情、赌博、暴力、凶杀、恐怖或教唆犯罪的；',
    '（6）侮辱或诽谤他人，侵害他人名誉、隐私、知识产权和其他合法权益的；',
    '（7）发送违法信息、拨打诈骗电话，未经接收方同意或请求、 或接收方明确表示拒绝的情形下发送骚扰信息或商业性信息、拨打骚扰话音或商业性话音的；',
    '（8）其他利用 我方通信资源与服务等制作、复制、发布、传播违法犯罪信息或进行违法活动的行为。',
    '12.您不得实施或协助他人实施下列危害我方网络安全和信息安全的行为， 否则我方有权暂停或限制向您提供通信服务，直至终止通信服务。 由此产生的后果，我方不承担责任：',
    '（1）侵入或非法控制我方网络或电信设备，盗接我方电信线路、非法复制我方电信码号；',
    '（2）非法获取、删除、修改我方网络或电信设备中存储、处理、传输的信息和应用程序；',
    '（3）利用我方网络从事窃取或破坏他人信息、损害他人合法权益的活动， 制作、传播、使用网络改号软件以及对他人进行骚扰的恶意软件；',
    '（4）提供从事入侵我方网络、干扰网络正常功能、传输扩散违法信息、窃取网络数据等危害网络安全活动的工具及制作方法；',
    '（5）设立用于实施诈骗，传授犯罪方法，制作或销售违禁物品、管制物品等违法犯罪活动的网站、通讯群组的；',
    '（6）故意制作、复制、传播计算机病毒或以其他方式攻击网络等电信设施；',
    '（7）使用未取得入网许可或可能影响网络安全、网络质量的终端设备或软件的；',
    '（8）危害我方网络安全和信息安全的其他行为。',
    '13.您同意我方基于提供通信服务、与您沟通联系、改善服务质量等目的， 收集、储存并使用您个人信息，包括身份信息、服务数据及日志信息等。 身份信息包括但不限于通过识别仪、高拍仪、纸质表单、互联网等方式收集的姓名、 出生日期、身份证件号码、拍照人像、住址、电话号码、电子邮箱等； 服务数据及日志信息包括但不限于您在使用我方服务过程中上传和产生的位置信息、 终端信息、通话记录、使用记录、订购信息、账单等。如您不同意提供或不同意我方收集、 储存并使用如上的某一或某些信息，您将可能无法成为我方的用户或无法享受我方提供的某些服务， 或无法达到相关服务拟达到的效果。本协议解除或终止后，我方不再收集新的您个人信息， 并将按照相关法律法规规定对本协议存续期间收集的您个人信息进行处理。',
    '14.未经您授权同意，我方不向除我方关联公司外的第三方提供您个人信息。 如您授权与我方有合作关系的银行、依法设立的其他征信机构、数据服务机构、 信息查询服务机构及信息验证服务机构等第三方向我方收集、核验您个人信息， 您同意我方有权向该等第三方提供相关的个人信息和核验信息。 其中我方关联公司是指我方现在或将来控制、受控制或与其处于共同控制下的任何公司、 机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力， 无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。',
    '15.为保护公共利益、优化通信服务质量、保障您通信服务知情权、及时警示通讯信息诈骗、 创新通信服务体验，您同意我方使用您个人信息通过营业网点、 网站、短信、互联网媒体、电子邮件、语音外呼等方式，向您告知社会公益、 通信服务、业务使用、诈骗风险警示等信息。',
    '16.您有权自主选择携号转网服务，您办理携号转网业务应当符合工业和信息化部携号转网有关文件的规定条件和程序。',
    '17.办理携号转网业务过程中，您同意我方将必要的您身份信息提供给工业和信息化部集中业务管理系统（CSMS系统）， 用于比对携入方和携出方登记的身份信息是否一致。',
    '三、我方的权利和义务',
    '1.我方在其通信网络与设施覆盖范围内，向您提供通信服务； 其中，我方在签订有自动漫游协议的国家和地区的运营商的网络覆盖范围 内为您提供国际移动通信漫游服务及港澳台地区移动通信漫游服务。 我方提供的通信服务应符合国家规定的通信服务质量标准。',
    '2.我方免费向您提供中华人民共和国境内 （为本协议目的，仅限大陆地区，不含港澳台） 火警、匪警、医疗急救、交通事故报警等紧急呼叫服务。',
    '3.我方根据生产经营成本、电信市场供求状况等因素，合理确定并公示电信业务资费方案。',
    '4.我方可以通过营业网点、网站、电话、广播、短信、彩信、电子邮件、电视、 公开张贴、信函、报纸、互联网媒体等一种或数种方式，公布、 通知或告知业务种类和服务项目、范围、时限、资费方案以及其他通信服务内容。',
    '5.我方可以通过电话热线、营业网点、网上营业厅、 掌上营业厅或短信等多种渠道为您提供业务办理、咨询、查询、障碍申告、投诉受理等服务。',
    '6.我方负责网络接入保障、提供相关设备的安装、调测和维护服务，具体服务内容及费用等详见相关业务说明。',
    '7.您向我方申告除网络覆盖和终端设备故障外的通信服务障碍， 我方应当自接到申告之日起，城镇48小时、农村72小时内修复或调通。',
    '8.我方因检修线路、设备搬迁、工程施工、网络建设等原因可能影响正常通信服务的， 应当提前72小时向社会公告，通告方式包括但不限于网站、广播、短信、电视、公开张贴、报纸、互联网媒体等。',
    '9.我方向您提供免费的电子账单服务，并有义务对账单进行解释。 我方对您计费原始数据保留期限为5个月（系统产生用户话单当月起后5个月，不含当月）， 法律另有规定除外。如数据保留期限内您对通信费用提出异议， 我方应当保存相应计费原始数据至异议解决。',
    '10.我方可以根据对您实际情况的综合评估，与您约定一定时间内的电信消费透支额度（“信用额度”）并可以进行周期性调整。',
    '11.我方有权根据具体业务类型按预付方式或后付方式向您收取通信费用：',
    '（1）预付方式下，您需预存费用。当账户余额加上信用额度（如有）不足以支付您拟使用的电信业务费用时， 您应当及时充值或交费，否则我方可暂停提供通信服务（“欠费停机”）。',
    '（2）后付方式下，您累计未交的费用到达信用额度、或到达交费期时， 您应当及时充值或交费，否则我方可暂停提供通信服务。 其中，您当月累计出账费用的交费期以中国电信各省分公司业务规定为准。',
    '（3）上述（1）、（2）欠费停机所涉业务，不包括单独的固定电话业务。 固定电话业务的欠费停机时间按照《中华人民共和国电信条例》的相关规定执行。',
    '12.您自欠费停机之日起60日内结清费用并申请复机的， 我方应当在收到您费用时起24小时内为您恢复暂停的通信服务（“复机”）。',
    '13.如您自欠费停机之日起满60 日仍未结清欠费和违约金， 我方有权自欠费停机第61日起终止提供通信服务，收回相应号码（“销户”）、 解除本协议，以及通过信函、电话、诉讼或委托第三方等形式追缴欠费和违约金， 并可以依法向征信机构提供您失信信息。',
    '14.为提供更为精准优质的服务，我方严格按照《网络安全法》、 《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号） 等法律法规的相关要求，在业务活动（包括但不限于业务受理系统登记、 纸质返档，通过网络接收、读取并记录）中收集、使用您个人信息， 我方可以依法对包含您在内的整体用户数据进行分析并加以利用。 我方承诺对您个人信息依法负有保密义务，不得泄露、篡改或毁损，不得非法出售或非法向他人提供， 并应用行业通行的安全技术和管理手段（如脱敏展示、访问权限等）防止未经授权的访问和使用。',
    '15.为遵守相关法律法规或者行政机关、 监管机构等有权部门依法提出的要求以及基于诉讼 / 仲裁需要，我方有权披露您的个人信息。',
    '四、特殊情况的责任承担',
    '1.您入网后，应当妥善保管原始票据（如押金票据等），发生遗失的，由您自行承担责任。',
    '2.您密码是您办理业务的重要凭证。您入网后应当立即设置您密码，并妥善保管。为方便办理业务， 我方也可以根据您申请以短信等方式提供随机密码，该密码可以作为办理业务的临时凭证。',
    '3.凡使用您密码或随机密码定制、变更或取消业务的行为，均被视为您或您授权的行为。 因您对密码保管不善造成的损失由您自行承担，但我方有义务协助您或公安机关调查相关情况。',
    '4.您应当合理管理通信费用支出，出现异常的高额通信费用时（指超过您此前3个月平均通信费用5倍以上的费用）， 我方一经发现，应当尽可能迅速告知您，如告知后未得到您确认的，我方有权暂停本协议约定的部分或全部服务。',
    '5.在您欠费停机的情况下，我方有权拒绝为您办理除交费、通信费用查询外的其他电信业务。',
    '6.一方违约给对方造成直接损失的，应当依法承担赔偿责任。',
    '7.因不可抗力而使本协议部分或全部不能履行的，部分或全部免除责任， 但法律另有规定的除外。不可抗力指不能预见、不能避免并不能克服的客观情况。',
    '8.您使用本协议项下通信服务过程中涉及的第三方服务（如第三方支付、银行转账、与第三方应用绑定等）， 由第三方承担责任。对于您提出的异议，我方可以协调第三方解决。',
    '五、协议的生效、变更、终止和解除',
    '1.除本协议另有约定外，本协议自您签字（您为自然人）或盖章（您为非自然人）、 且我方盖章之日起生效；通过电子渠道、电子签名、电子协议或其他电子数据形式签订本协议的， 您以点击确认等方式表示同意本协议的电子数据进入我方系统的时间视为您签字时间。 如您为办理号码携入并签订本协议的， 甲、乙双方签字或盖章后，本协议于我方收到 携号转网成功生效结果告知之时起生效。',
    '2.您如终止使用通信服务，应当结清通信费用及违约金（如有）， 并至我方营业网点申请销户或号码携出，销户或号码携出后本协议终止。',
    '3.未经双方同意并办理有关手续，甲、我方不得将依据本协议办理的业务全部或部分转让给第三方。',
    '4.您申请销户或号码携出时账户仍有余额的， 我方根据实际情况将余额一次性转入您指定的我方运营的其他有效号码的账户中， 或以中国电信充值卡等方式退还；您通过参与赠送、优惠、兑换等营销活动获取的余额， 不予退还，如按营销活动的业务规则另有约定的，按其业务规则处理。',
    '5.因我方电信业务经营许可证的业务范围、业务种类发生改变，或因技术进步、 国家政策变动等原因，我方无法向您继续提供原有业务或需对原有业务的服务方式、 服务功能、操作方法、业务号码等进行调整时， 我方有权变更或终止本协议，但应当提前发布公告，提出解决方案，做好客户服务。',
    '6.有下列情形之一的，我方有权单方终止或解除协议：',
    '（1）您未按照用户真实身份信息登记要求， 提供的登记资料不真实、不准确、不完整、无效，或经我方通知后未在合理期限内配合提供的；',
    '（2）您存在本协议第二条第11款、第12款所列任一行为，或由此引起用户投诉或举报的；',
    '（3）您以担保方式取得业务使用权的情况下，违反担保约定或担保人无能力履行担保义务的；',
    '（4）您未办理相关手续，自行改变通信服务使用性质、用途，或二次转售、倒卖电话卡，或自行转让协议的；',
    '（5）您利用我方服务从事相应活动，依法应当取得而未取得或丧失相应许可或备案；',
    '（6）您自欠费停机之日起60日内仍未结清所有欠费、违约金、赔偿金等费用的；',
    '（7）有权机关要求我方停止对您提供通信服务的；',
    '（8）您原因造成本协议无法履行的；',
    '（9）法律、法规、规章及规范性文件规定的其他情形。',
    '7.本协议终止或解除后，我方继续保留向您追交所欠费用的权利， 并有权收回您原使用的业务号码（销户的号码）、IP地址等电信资源。 为保证您权益，我方对销户的业务号码至少冻结90日，方可重新分配给其他用户使用。',
    '8.您改号、销户或过户时应当主动注销或解绑在该号码上的注册或绑定的第三方应用 （如微信、支付宝）、银行卡（如银行验证号码）等信息、以及第三方软件，自行解除银行托收、 代扣等第三方协议，自行卸载您加载的第三方软件，避免造成个人信息泄露； 否则由此造成您或其他人损失由您承担。',
    '六、其他约定',
    '1.所有因本协议引起的或与本协议有关的争议， 双方将通过友好协商解决。协商不成的，您可向我方所在地有管辖权的人民法院提起诉讼。',
    '2.如果本合同的任何条款在任何时候变成不合法、 无效或不可强制执行但不从根本上影响本合同的效力时，本合同的其他条款不受影响。',
    '3.您办理各类业务所签署的业务登记单为本协议的一部分，业务登记单与本协议条款冲突的部分以业务登记单为准。 我方以公告等书面形式公开做出的服务承诺为本协议的补充，该等服务承诺与本协议冲突的部分以服务承诺为准。',
    '（您方承诺：本人已经充分、完整阅读并理解本协议所述全部条款及条件。）',
  ],
};

let realProtocol = {
  title: '实名登记说明',
  content: [
    '尊敬的客户：',
    '根据工信部2018年9月1日实名新规要求您下单时需填写机主本人姓名及身份证号码，套餐电话卡到手后需激活，上传身份证正面、反面、及本人正面免冠照片，线上登记的机主姓名需与实名开户的机主姓名与身份证一致，开户和激活会将信息在国政通联网对比，通过后才能激活使用',
  ],
};

let protectProtocol = {
  title: '个人信息保护政策',
  content: [
    '尊敬的客户：',
    '感谢您接受我们的服务，在本次以及后续的服务中，广州骏伯网络科技有限公司及其子公司、孙公司（包含霍尔果斯创拓网络科技有限公司、广州互联网络科技有限公司、广州名璟科技有限公司、广州弘梵科技有限公司、北京骏辉网络科技有限公司、霍尔果斯骏伯网络科技有限公司、广州骏爵网络科技有限公司、深圳市创享无限网络科技有限公司、广州新景网络科技有限公司、广州新燃网络科技有限公司、广州新能网络科技有限公司、千坤信息科技（广州）有限公司、广州匠之心文化传媒有限公司，简称“骏伯”或“我们”“平台”）作为专注于互联网营销推广，助力品牌方运营营销、为用户提供丰富个性化优质产品服务，致力于让“推广-触达”更便捷的数字化营销、综合资讯推广服务平台，会严格遵守相关法律法规与本个人信息保护政策，保护您的个人信息。同时，广州骏伯网络科技有限公司及其子公司、孙公司（以下合称为“骏伯”或“我们”）是三大运营商（以下简称“运营商”）的授权合作代理商，通过互联网媒体渠道在我们运营的互联网页面、微信公众号（统称为“平台”）上，基于运营商授权，在其授权范围内宣传并销售运营商的号卡、流量、宽带服务类通信产品，帮助用户（以下称为“用户”或“您”）完成对应号卡订单的提交并提供订单查询服务。作为运营商深度合作伙伴，我们致力于通过平台为用户提供精选靓号、话费、流量、宽带等全网更具性价比的通信产品服务（以下均简称为“我们的产品或/和服务”）。',
    '本政策适用于骏伯通过平台为您提供的上述产品和服务。',
    '最近更新日期：2023年11月。',
    '电子邮件：service@gzjunbo.net',
    '本政策将帮助您了解以下内容：',
    '一、定义与解释',
    '二、我们如何收集和使用您的个人信息',
    '三、我们如何使用 Cookie 和同类技术',
    '四、我们如何委托处理、向第三方提供、转移、公开您的个人信息',
    '五、我们如何存储和保护您的个人信息',
    '六、您的权利',
    '七、第三方网站和服务',
    '八、我们如何处理敏感个人信息',
    '九、您的个人信息如何在全球范围转移',
    '十、本个人信息保护政策如何更新',
    '十一、如何联系我们',
    '骏伯深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守“合法、正当、必要和诚信原则”，严格遵守《中华人民共和国民法典》《中华人民共和国个人信息保护法》等适用法律有关个人信息保护的规定。同时，骏伯承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。',
    '请您在使用我们的产品（或服务）前，仔细阅读并了解本《个人信息保护政策》（以下简称“政策”），并确认了解我们对您个人信息的处理规则。一旦您点击确认接受我们的政策或在我们的政策更新后我们重新获得您的确认，即表示您认可并接受本政策（含更新版本）的所有内容，并同意骏伯按本政策收集、使用、保存、共享、转让或披露您的相关个人信息。',
    '一、定义与解释',
    '（1）个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。',
    '（2）敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。',
    '（3）个人信息主体指个人信息所标识的自然人。',
    '（4）去标识化指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。',
    '（5）匿名化指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。',
    '（6）设备是指可用于访问我们服务的装置，例如台式计算机、笔记本电脑、平板电脑或智能手机。',
    '（7）唯一设备标识符（专属 ID 或 UUID）是指由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（移动设备的 IMEI 号）。',
    '（8）每台上网的设备都会指定一个编号，称为互联网政策 (IP) 地址。这些编号通常都是根据地理区域指定的。IP 地址通常可用于识别设备连接至互联网时所在的位置。',
    '（9）Global Positioning System（全球定位系统）的简称。您使用的设备上的GPS信号接收器可以从GPS卫星收到信号并利用传来的信息计算您的位置。',
    '（10）Wi-Fi是一种允许电子设备连接到一个无线局域网（WLAN）的技术，通常我们所说的无线网络。',
    '（11）基站，即公用移动通信基站，是无线电台站的一种形式，是指在一定的无线电覆盖区中，通过移动通信交换中心，与移动电话终端之间进行信息传递的无线电收发信电台。',
    '（12）SSL（Secure Socket Layer）又称为安全套接层，是在传输通信政策（TCP/IP）上实现的一种安全政策。SSL支持各种类型的网络，同时提供三种基本的安全服务，均通过使用公开密钥和对称密钥技术以达到信息保密的效果。',
    '（13）骏伯在本政策中所述的“自动收集”包括以下方式：',
    '（13-1）CookieCookie是您浏览网页时，网站服务器放在客户端（您的计算机、移动电话或其他智能终端内）里面的一个小小的文本文件，可用于改善您的使用体验。',
    '（13-2）Web beacon是装嵌在网站或电邮内的电子图像文件案或其他技术，可用于计算访客数目、记录您是否及何时阅览电邮或网站，或用以使用某些Cookie。',
    '（13-3）Log files储存自动收集的若干数据。该等数据包括互联网政策（IP）地址、浏览器类型、互联网服务提供商（ISP）、引用/退出页面、操作系统、日期/时间戳和点击流数据等。',
    '（13-4）ET Tag是在互联网浏览器与互联网服务器之间背后传送的HTTP政策标头，可代替Cookie，用以追踪个别使用者，使我们可更深入地了解和改善我们的服务。',
    '（13-5）JavaScript是一种编程语言，用于制作更具互动性和动态的网页。JavaScript可以设定Cookie、阅读Cookie及删除Cookie。',
    '（14）个人信息的处理包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。',
    '二、我们如何收集和使用您的个人信息',
    '我们根据合法、正当、必要的原则，出于如下所述的目的收集和使用您的个人信息。',
    '1.您选择使用骏伯的产品和服务，需要向我们提供或允许我们收集并使用的必要信息包括：',
    '（1）基于我们提供的产品和/或服务的地域性特征，在您点击进入我们的产品销售页面时，我们会从微信（如您通过公众号进入我们的产品销售页面）间接或直接（如您通过网页直接进入我们的产品销售页面）获取您的地理位置，该等地理位置系基于您使用的IP地址获得的非精确（仅可到城市级别）的地理位置信息，以便于我们更好地向您展示与您所在城市匹配的产品和/或服务；',
    '（2）您在购买我们平台提供的产品和/或服务、提交您的服务需求时，我们需要收集您的姓名、电话号码、身份证号码，并将其提供给您所购买的产品和/或服务的运营商（以您提交服务需求时页面标注的运营商为准），便于其对您进行身份核验和进行产品和/或服务的开通、办理业务。',
    '（3）为了便于运营商向您寄送相关产品和/或服务，我们需要收集您的电话号码、收件地址和收件人信息，并将其提供给您所购买的产品和/或服务的运营商，便于其为您提供产品和/或服务的寄送服务。',
    '（4）我们会在您提交产品和/服务购买订单后，我们会收集您在使用我们的产品或服务过程中所产生的订单信息，具体包括:订单编号、订单来源、商品名称、下单时间、用户ID、订单价格、实际支付价格、支付方式，如您选购的商品涉及邮寄服务，我们还将收集您填写的收货信息，包括收货人、联系电话、收货地址等。此外，我们亦将从运营商处收集对应的订单所涉商品和/或服务的物流信息和开通/使用状态。我们将使用该等订单信息为您提供订单信息展示和查询服务，为您跟进处理存在异常的订单，并对您进行必要的客户回访。',
    '（5）为了保障系统安全，确保我们可以与您进行有效的互联网通信，我们将会收集您的如下信息：',
    '（5-1）日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们可能会自动收集您对我们服务的详细使用情况，作为有关网络日志保存。包括您的IP地址、浏览器的类型、电信运营商、网络环境、使用的语言、访问日期和时间及您访问的网页操作记录、Push打开记录、停留时长、刷新记录。',
    '（5-2）设备信息：我们会根据您在产品使用过程中授予的具体权限，接收并记录您所使用的设备相关信息（例如浏览器及版本、操作系统及版本、设备分辨率信息）、设备所在位置相关信息（IP地址）。',
    '（5-3）请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，该个人信息我们将依法依规进行处理并取得您的同意。',
    '（6）我们会根据您的订单信息、您的日志信息、设备信息，使用您提供的您的联系电话与您取得联系，向您推荐与您匹配的相关运营商商品和/或服务。您授权同意我们及关联方、业务合作方向你一次或多次进行产品或者服务及产品的宣传及资讯信息触达，产品及资讯内容包括但不限于：相关运营商号卡、流量、宽带服务类通信产品。触达方式包括短信、电话或邮件等。如果您拒绝我们的该等推荐服务的，可以通过回复拒绝短信（拒绝方式以短信内容提示为准）或电话口头告知的方式予以拒绝；您拒绝我们的该等服务后，我们不会再使用您的个人信息向您推荐与您匹配的相关运营商商品和/或服务。',
    '（7）通过使用您同意我们收集的个人信息（注册信息、网页浏览信息、我们自行合法采集的个人信息），我们得以向您提供其它的商业营销内容，您知悉这是我们面向用户提供的主要服务。您同意我们向您展现或触达我们的商业合作伙伴提供的产品及服务的信息流或者广告/推广信息。具体我们可能会通过短信、电话或其它方式向您提供您可能感兴趣的商业广告、商业活动等商业性信息。例如，我们可能会基于您的偏好特征向您触达培训、教育、保险、金融理财、电商等领域的产品或服务的商业信息。我们会严格保护您的个人信息，不会超出法律法规和本政策的内容将您的个人信息提供给第三方。如您不想接收我们给您发送的商业营销信息，您可以根据营销短信的内容提示或者本政策第六条公示的方式及第十一条公示的联系方式进行退订、撤回同意等，来停止接受我们提供的服务。除上述基础业务使用目的外，作为助力品牌方运营营销、为用户提供丰富个性化优质产品服务，致力于让“推广-触达”更便捷的综合资讯营销推广服务平台，我们可为您提供我们合作方、关联方提供的多行业、多领域的产品或服务的商业广告、资讯信息。如果您选择同意当前营销页面中的单独同意书，结合收集到的其它个人信息，我们可以为您提供以上附加业务。',
    '（8）我们会收集您通过我们的客服或参加骏伯举办的活动时所提交的个人信息，以为您提供您所要求的客户服务或对应的优惠活动服务。',
    '（9）我们会收集您通过其他方式向骏伯所提交的个人信息，用于您提交该等个人信息时申明的目的和用途。',
    '2.我们收集上述必要个人信息及经您同意后向我们提供或允许我们收集的非必要个人信息，还将用于以下用途：',
    '（1）对您的个人信息在内的用户数据库进行统计分析，以供我们内部经营决策使用。',
    '（2）持续开发和优化产品服务我们使用收集到的个人信息保障我们及关联方、合作方的业务运营，例如评估、维护和改进产品和服务的性能，当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的相关服务。为迭代和开发新的产品和服务，我们可能对收集的个人信息加以统计分析辅助决策，并将数据分析结果提供给第三方，但请您放心，该等数据分析结果不包含您的任何个人信息。',
    '3. 事先征得授权同意的例外',
    '根据相关法律法规及国家标准，在以下情形中，我们会依法收集并使用您的个人信息无需征得您的同意：',
    '-为订立、履行您作为一方当事人的合同所必需； ',
    '- 为履行法定职责或者法定义务所必需；',
    '-为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；',
    ' -为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；',
    ' -依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的您的个人信息；',
    ' -法律、行政法规规定的其他情形。',
    '4. 补充说明 当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。 三、我们如何使用Cookie、同类技术',
    '1. cookie 为确保网站正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。Cookie',
    '通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，网站能够存储您的偏好或购物篮内的商品等数据。',
    '我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时亲自更改用户设置。',
    '2. 网站信标和像素标签 除 Cookie外，我们还可能会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击URL。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。',
    '3. Do Not Track（请勿追踪） 很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。',
    ' 四、我们如何委托处理、向第三方提供、转移、公开您的个人信息',
    '1.委托处理',
    '我们的一些业务功能和技术服务可能由外部供应商提供。我们委托处理个人信息的，会与受托人明确约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。',
    '我们会严格要求受托人按照约定处理个人信息，不得超出约定的处理目的、处理方式等处理个人信息；如发生委托合同不生效、无效、被撤销或者终止等情形，我们将要求受托人将个人信息返还或者予以删除，不得保留。',
    '2.向第三方提供 我们不会向本公司以外的任何公司、组织和个人提供您的个人信息，除非获得您的明确同意。',
    '目前为了向您提供我们的服务，我们需向合作运营商分享您的个人信息，以实现向您提供您需要的服务，包括：',
    '-将您的身份信息提供给运营商，以便其对您进行身份验证、履行“电信服务实名制”的法定要求；',
    '-将您的收件地址、电话号码和收件人提供给运营商或其他业务合作方，以便于其对您寄送您购买的产品和/或服务。',
    '相关运营商的联系方式和隐私政策请见“单独同意书”。',
    ' 您同意并授权，骏伯基于向您提供更精准营销/产品推荐的目的，可能会向我们合作的第三方机构 (包括同盾科技有限公司、电信运营商等)查询、核验您的信息(该信息是无法识别用户的去身份化的数据，如加密处理的OAID等广告标识符、曝光、点击等广告数据、模糊化的模型评分数据），您授权并同意我们将您的信息（已去身份化）提供给数据服务商（包括同盾科技有限公司等）并同意其进一步向第三方（包括朴道征信有限公司等）查询、核验您的信息。请您放心，就前述合作中所涉信息对外提供上，我们会采用去标识化、匿名化或加密算法等技术措施对数据进行处理，确保合作方无法直接识别到您的身份。我们将根据法律法规要求并参照行业最佳实践，不断完善和提升对您个人信息的安全保障水平。（ 第三方（数据服务商）供应商清单）',
    '3.转移',
    '因合并、分立、解散、被宣告破产、业务/资产出售等原因需要转让个人信息的，我们会向您告知接收方的名称或者姓名和联系方式。我们会要求接收方继续受本政策的约束，否则我们将要求接收方重新取得您个人的同意。',
    '4.公开 我们不会公开我们处理的您的个人信息，除非取得您的单独同意。',
    '5.向第三方提供、公开个人信息时事先征得授权同意的例外,以下情形中，向第三方提供、公开您的个人信息无需事先征得您的同意：',
    '-为订立、履行您作为一方当事人的合同所必需；； - 为履行法定职责或者法定义务所必需；',
    '-为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；',
    '-为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；',
    '-依照法律规定在合理的范围内处理您自行公开或者其他已经合法公开的您的个人信息；',
    '-法律、行政法规规定的其他情形。',
    '我们会在合理的范围内处理您自行公开或者其他已经合法公开的个人信息，您明确拒绝的除外。我们处理上述已公开的个人信息，对于可能对您个人权益产生重大影响的，我们会依照法律规定取得您个人的同意。',
    '五、我们如何存储和保护您的个人信息 1. 我们如何存储信息 1）存储信息的地点我们遵守法律法规的规定，收集的有关您的信息和资料将保存在中华人民共和国境内骏伯的服务器上。以下情形除外：',
    '法律法规有明确规定； - 获得您的明确授权； - 您通过互联网进行跨境交易等个人主动行为。',
    '存储信息的期限您同意并授权：就您每次通过平台购买产品和/或服务提交及产生的个人信息，我们对其的保存期限为3年，并在保存期限内按照本政策的规定进行处理。但法律法规对我们收集的您的个人信息的保存期限另有规定的除外。',
    '如在前述存储期限内我们的相关服务发生停止运营的情形时，我们将采取包括推送通知和公告形式在内的通知您，并在合理的期限内删除服务器中留存的您的个人信息或进行匿名化处理。',
    '2. 我们如何保护您的个人信息',
    '（1）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，在您的浏览器与“服务”之间交换数据（如身份证信息）时受SSL 加密保护；我们同时对我们网站提供 https安全浏览方式；我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击；我们会部署访问控制机制，确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。',
    '（2）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。',
    '（3）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他我们用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。',
    '（4）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将依法承担相应的法律责任。',
    '（5）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话或推送通知等任一可行的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。',
    ' 同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。 ',
    '六、您的权利',
    ' 按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利，您可以参考下面的步骤进行相关操作：',
    '1.撤回同意的权利鉴于我们是基于您的同意处理您的个人信息，您可以通过我们的在线客服或拨打客服电话联系我们，撤回您的同意，我们将在15个工作日内响应您的需求。',
    '请您知悉，我们提供的产品和服务需要一些必要的个人信息才能得以完成（见本政策“第二部分”），对于此类必要信息，如您撤回您的同意，我们将无法为您提供我们的产品或服务；对于额外收集的个人信息的收集和使用，您有权随时向我们撤回您的同意。',
    ' 在我们依照您的要求处理您的撤回同意请求后，我们将不再处理您撤回同意所涉及的个人信息，同时我们也将无法为您提供对应的或全部的服务。但您的撤回同意，不影响撤回前基于您的同意已进行的个人信息处理活动的效力。',
    '2. 查阅、复制您的个人信息',
    '您有权查阅、复制您的个人信息，法律法规规定的例外情况除外。如果您想行使数据查阅权、复制权，可以随时通过我们的在线客服或拨打客服电话与我们联系。我们将在15个工作日内回复您。',
    '3您请求将您的个人信息转移至您指定的个人信息处理者的，如果符合国家网信部门规定条件的，我们会提供转移的途径。如果您想行使前述权利，可以随时通过我们的在线客服或拨打客服电话与我们联系，我们将在15个工作日内回复您。',
    '4.更正、补充您的个人信息',
    '当您发现我们处理的关于您的个人信息有错误或需要补充时，您有权要求我们做出更正、补充。您可以通过发送电子邮件【service@gzjunbo.net】联系我们。我们将在15个工作日内对您的个人信息予以核实，并及时更正、补充。',
    ' 5.删除您的个人信息 在以下情形中，我们会主动删除且您有权要求我们删除您的个人信息：',
    '（1）我们的处理目的已实现、无法实现或者为实现处理目的不再必要；',
    '（2）我们停止提供产品或者服务，或者保存期限已届满； （3）您撤回同意；',
    '（4）我们违反法律、行政法规或者违反约定处理个人信息； （5）法律、行政法规规定的其他情形。',
    '如果删除个人信息从技术上难以实现的，我们会停止除存储和采取必要的安全保护措施之外的个人信息处理活动。',
    ' 6.您行使权利的指引为保障安全，在行使权利时您可能需要向我们书面提出，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。',
    '我们将在15个工作日内做出答复。如您不满意，还可以通过以下途径投诉： 拨打客服电话：【 020-38468075】',
    '我们的政策不适用于第三方提供的产品或服务。您使用我们的产品或服务可能包括第三方的产品或服务，其中一些会以第三方网站的链接形式提供，还有一些会以SDK、API等形式接入。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的个人信息保护政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的个人信息保护政策不适用通过我们的服务链接的其他网站。',
    '八、我们如何处理敏感个人信息',
    '1．只有在具有特定的目的和充分的必要性，并采取严格保护措施的情形下，我们才会处理您的敏感个人信息。',
    '2．处理您的敏感个人信息我们会取得您的单独同意；法律、行政法规规定处理敏感个人信息应当取得您的书面同意的，我们会取得您的单独书面同意。',
    '3．我们处理您的敏感个人信息的，会向您告知处理敏感个人信息的必要性以及对您个人权益的影响，除非法律另有规定。',
    '4．我们如何处理儿童的个人信息 （1）尽管各地法律和习俗对儿童的定义不同，但我们将不满 14',
    '周岁的任何人均视为儿童。',
    '（2）我们不为14周岁以下（含）的儿童提供平台相关的产品和/服务。如您未满14周岁，请停止使用我们的平台产品和/或服务。',
    '（3）通常而言，在未取得监护人同意的情况下，我们不会主动收集或处理儿童个人信息。然而，由于技术上的限制，在某些场景下，特别是提供线上服务时，我们无法识别用户的年龄。在这种情况下，用户将被我们视为有完整且合法的权利将其个人信息根据相关法律提供给我们。如果我们在无法识别用户年龄的情况下，未经监护人的许可收集了儿童个人信息，请监护人及时通知我们，我们在获悉该等情况后将会及时删除此类信息。如您对您所监护的儿童的个人信息有疑问，请通过本政策第十一条所述联系方式与我们联系。',
    '九、您的个人信息如何在全球范围转移 只有在完全符合我国法律规定的前提下，我们才会将您的个人信息跨境提供。',
    '十、本个人信息保护政策如何更新 我们的个人信息保护政策可能变更。 1.',
    '未经您明确同意，我们不会削减您按照本政策所应享有的权利。我们会在专门页面上发布对政策所做的任何变更并重新征得您的同意。',
    '2. 对于重大变更，我们还会提供更为显著的通知，包括我们会通过网站公示的方式进行通知甚至向您提供弹窗提示。',
    '本政策所指的重大变更包括但不限于： -',
    '我们处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等发生变化； -',
    '个人信息共享、转让或公开披露的主要对象发生变化； - 您参与个人信息处理方面的权利及其行使方式发生重大变化；',
    '-我们的名称、联系方式发生了变化。 十一、如何联系我们',
    '如您在使用骏伯的产品或服务的过程中对本个人信息保护政策有任何疑问或建议，请通过以下联系方式与我们联系：',
    '公司名称：广州骏伯网络科技有限公司 地 址：广州市天河区广园东路博汇街6号5栋302、303、305房',
    '网络安全负责人：王利锋 电子邮件：service@gzjunbo.net',
    '为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在验证您的身份后处理您的请求。一般情况下，我们将在15个工作日内回复。',
    '如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过以下外部途径寻求解决方案：向【广州骏伯网络科技有限公司住所地有管辖权的法院提起诉讼】。',
  ],
};
