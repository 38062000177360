import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './page/homePage/index';
import SearchOrderPage from './page/searchOrder/index';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/searchorder" element={<SearchOrderPage />} />
      </Routes>
    </BrowserRouter>
  );
}
